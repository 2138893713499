import gql from 'graphql-tag';

export const SIGNIN_MUTATION = gql`
  mutation Login($email: String!, $password: String!) {
    login(input: { email: $email, password: $password }) {
      timezone
      darkModePreference
      permissions {
        can_edit_orders
        can_edit_crops_blends
        can_edit_tasks
        can_complete_tasks
      }
      preferredWeightUnit
      preferredDateFormat
      language
      success
    }
  }
`;
